<template>
  <section>
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/service"><a>Zgłoszenia serwisowe</a></router-link>
          <router-link tag="li" to="/service/add" class="is-active"><a>Dodaj nowe zgłoszenie serwisowe</a></router-link>
        </ul>   
      </nav>
    </portal-target>

    <ServiceForm />
  </section>
</template>

<script>
  import ServiceForm from '@/views/Service/ServiceForm'
  export default {
    name: 'ServiceAdd',
    components: {
      ServiceForm
    },
        
  }
</script>

<style lang="scss" scoped>

</style>