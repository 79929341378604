<template>
  <section v-if="dictionary && Object.keys(dictionary).length > 0">
    <!-- <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/service"><a>Zgłoszenie serwisowe</a></router-link>
          <router-link tag="li" :to="{ name: 'ServiceDetails', params: { id: this.$route.params.id }}" class="is-active is-uppercase"><a>{{ name }}</a></router-link>
        </ul>   
      </nav>
    </portal-target> -->
    <div class="ym-toptitle-box">
      <fixed-header :threshold="0" fixedClass="ym-toptitle-fixed">
        <div id="toptitle" class="level ym-toptitle">
          <div class="level-left">
            <router-link
              tag="a"
              to="/service/"
              class="button xbtn is-medium is-transparent"
              ><icon name="x"></icon
            ></router-link>
            <b
              v-if="order.priority_id > 0"
              class="badge"
              :style="`margin-right: 8px; color: ${order.priority.colour}`"
            >
              {{ order.priority.name }}
            </b>
            <div style="margin-right: 2rem">
              <p>Numer zlecenia</p>
              <h2 class="title">{{ name }}</h2>
            </div>
            <div class="level-left-status">
              <p>Status</p>
              <h2 class="title" :style="statusColor()" v-if="order.status_id">
                {{ statusName().toUpperCase() }}
              </h2>
            </div>
          </div>
          <div class="buttons level-right">
            <!-- <button @click="orderRemove" class="button is-medium is-light"><icon name="trash"></icon><span>Usuń</span></button> -->
            <!-- <a :href="order.pdf_protocol" target="_blank" class="button is-medium is-light"><icon name="download"></icon><div>PDF</div></a>  -->
            <button
              v-if="order && order.status_id !== 10"
              @click="showStatusModal = true"
              class="button is-medium is-light"
            >
              <icon name="edit"></icon><span>Status</span>
            </button>
            <router-link
              v-if="online"
              tag="a"
              :to="{
                name: 'ServiceEdit',
                params: { id: parseInt(this.$route.params.id) },
              }"
              class="button is-medium is-black"
              ><icon name="edit"></icon><span>Edytuj</span></router-link
            >
            <!-- order.technical_id === user.id &&  <a class="button is-medium is-dark"><icon name="check"></icon><span>Wybierz</span></a> -->
          </div>
        </div>
      </fixed-header>
    </div>
    <div
      :class="
        order && order.status_id === 20
          ? 'ym-whitebg pa40 mb-72'
          : 'ym-whitebg pa40 mb-24'
      "
    >
      <div class="columns is-mobile is-variable is-3 is-multiline">
        <div class="column is-6-mobile is-4-tablet" v-if="order.number">
          <p class="label-small">Numer zlecenia</p>
          <p class="label is-uppercase">{{ order.number }}</p>
        </div>
        <div class="column is-6-mobile is-4-tablet" v-if="order.created_at">
          <p class="label-small">Data przyjęcia</p>
          <p class="label is-uppercase">{{ order.created_at }}</p>
        </div>
        <div class="column is-6-mobile is-4-tablet" v-if="order.status_id">
          <p class="label-small">Status zlecenia</p>
          <p class="label is-uppercase">{{ statusName().toUpperCase() }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3 is-multiline">
        <div class="column is-6-mobile is-4-tablet" v-if="order.operator_name">
          <p class="label-small">Zlecenie przyjął</p>
          <p class="label is-uppercase">{{ order.operator_name }}</p>
        </div>
        <div class="column is-6-mobile is-4-tablet" v-if="order.technical_name">
          <p class="label-small">
            {{ dictionary["table_order_technical_name_field_label"] }}
          </p>
          <p class="label is-uppercase">{{ order.technical_name }}</p>
        </div>
        <div class="column is-6-mobile is-4-tablet" v-if="order.repared_at">
          <p class="label-small">Data naprawy</p>
          <p class="label is-uppercase">{{ order.repared_at }}</p>
        </div>
      </div>
      <div
        class="columns is-mobile is-variable is-3 is-multiline"
        v-if="order.place_id > 0 || order.priority_id > 0"
      >
        <div
          class="column is-6-mobile is-4-tablet"
          v-if="order.priority_id > 0"
        >
          <p class="label-small">
            {{ dictionary["table_order_priority_id_field_label"] }}
          </p>
          <p class="label" :style="`color: ${order.priority.colour}`">
            {{ order.priority.name }}
          </p>
        </div>
        <div class="column is-6-mobile is-4-tablet" v-if="order.place_id > 0">
          <p class="label-small">
            {{ dictionary["table_order_place_id_field_label"] }}
          </p>
          <p class="label">{{ order.place.name }}</p>
        </div>
      </div>
      <hr />
      <div class="columns is-mobile is-variable is-3 is-multiline">
        <div class="column is-6-mobile is-4-tablet" v-if="order.device_type">
          <p class="label-small">
            {{ dictionary["table_order_device_type_field_label"] }}
          </p>
          <p class="label is-uppercase">{{ order.device_type }}</p>
        </div>
        <div class="column is-6-mobile is-4-tablet" v-if="order.device_name">
          <p class="label-small">
            {{ dictionary["table_order_device_name_field_label"] }}
          </p>
          <p class="label is-uppercase">{{ order.device_name }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3 is-multiline">
        <div class="column is-6-mobile is-4-tablet" v-if="order.device_serial">
          <p class="label-small">
            {{ dictionary["table_order_device_serial_field_label"] }}
          </p>
          <p class="label is-uppercase">{{ order.device_serial }}</p>
        </div>
        <div
          class="column is-6-mobile is-4-tablet"
          v-if="order.device_address"
          style="display: none"
        >
          <p class="label-small">Miejsce postoju produktu</p>
          <p class="label is-uppercase">{{ order.device_address }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3 is-multiline">
        <div class="column is-6-mobile is-4-tablet">
          <p class="label-small">
            {{ dictionary["table_order_is_warranty_field_label"] }}
          </p>
          <p class="label is-uppercase">
            {{ order.is_warranty ? "TAK" : "NIE" }}
          </p>
        </div>
        <div
          class="column is-6-mobile is-4-tablet"
          v-if="order.warranty_status_id !== 0"
        >
          <p class="label-small">
            {{ dictionary["table_order_warranty_status_id_field_label"] }}
          </p>
          <p class="label is-uppercase">{{ warrantyName }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3 is-multiline">
        <div
          class="column is-6-mobile is-4-tablet"
          v-if="order.device_hours"
          style="display: none"
        >
          <p class="label-small">Liczba motogodzin</p>
          <p class="label is-uppercase">{{ order.device_hours }}</p>
        </div>
        <div
          class="column is-6-mobile is-4-tablet"
          v-if="order.service_type_id !== 0"
        >
          <p class="label-small">
            {{ dictionary["table_order_service_type_id_field_label"] }}
          </p>
          <p class="label is-uppercase">{{ order.service_type_name }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3 is-multiline">
        <div
          class="column is-12-mobile is-6-tablet"
          v-if="order.device_service_work"
        >
          <p class="label-small">
            {{ dictionary["table_order_device_service_work_field_label"] }}
          </p>
          <p class="label is-uppercase">{{ order.device_service_work }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3 is-multiline">
        <div class="column is-12-mobile is-6-tablet" v-if="order.service_time">
          <p class="label-small">
            {{ dictionary["table_order_service_time_field_label"] }}
          </p>
          <p class="label is-uppercase">{{ order.service_time }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3 is-multiline">
        <div
          class="column is-12-mobile is-6-tablet"
          v-if="order.operator_notes"
        >
          <p class="label-small">
            {{ dictionary["table_order_operator_notes_field_label"] }}
          </p>
          <p class="label is-uppercase">{{ order.operator_notes }}</p>
        </div>
      </div>
      <div v-if="order_service.length > 0">
        <hr />
        <div class="columns is-variable is-6">
          <div class="column is-12">
            <b>{{ dictionary["table_order_is_service_field_label"] }}</b
            ><br /><br />
            <div class="ym-table ym-table__info">
              <div
                v-for="service in order_service"
                :key="service.id"
                class="ym-tableItem ym-tableItem__prl"
              >
                <div>{{ service.category }}</div>
                <div>{{ service.name }}</div>
                <div>{{ service.minutes }} min</div>
                <div>{{ service.price }} zł</div>
              </div>
              <div class="ym-tableItem ym-tableItem__prl">
                <div>&nbsp;</div>
                <div><b>Podsumowanie</b></div>
                <div>{{ orderServiceMinutes(order_service) }} min</div>
                <div>{{ orderServiceCash(order_service) }} zł</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-variable is-6" v-if="order.service_price">
        <div class="column is-12-mobile is-6-tablet">
          <p class="label-small">
            {{ dictionary["table_order_service_price_field_label"] }}
          </p>
          <p class="label is-uppercase">{{ order.service_price }} zł</p>
        </div>
      </div>
      <div v-if="order && order.status_id === 40">
        <hr />
        <div
          class="columns is-mobile is-variable is-6"
          v-if="order.service_fault"
          style="display: none"
        >
          <div class="column is-12">
            <p class="label-small">Opis usterki</p>
            <p class="label is-uppercase">{{ order.service_fault }}</p>
          </div>
        </div>
        <div
          class="columns is-mobile is-variable is-6"
          v-if="order.service_description"
          style="display: none"
        >
          <div class="column is-12">
            <p class="label-small">Opis wykonywanych czynności</p>
            <p class="label is-uppercase">{{ order.service_description }}</p>
          </div>
        </div>
        <div
          class="columns is-mobile is-variable is-6"
          v-if="order.technical_service"
          style="display: none"
        >
          <div class="column is-12">
            <p class="label-small">
              {{ dictionary["table_order_technical_service_field_label"] }}
            </p>
            <p class="label is-uppercase">{{ order.technical_service }}</p>
          </div>
        </div>
        <div class="columns is-mobile is-variable is-6">
          <div class="column is-12" v-if="order.technical_notes">
            <p class="label-small">
              {{ dictionary["table_order_technical_notes_field_label"] }}
            </p>
            <p class="label is-uppercase">{{ order.technical_notes }}</p>
          </div>
        </div>
        <div
          style="display: none"
          class="columns is-mobile is-variable is-3 is-multiline"
        >
          <div class="column is-3">
            <p class="label-small">Liczba ha</p>
            <p class="label is-uppercase">{{ order.device_area }}</p>
          </div>
          <div style="display: none" class="column is-3">
            <p class="label-small">Liczba bali</p>
            <p class="label is-uppercase">{{ order.device_bales }}</p>
          </div>
          <div style="display: none" class="column is-3">
            <p class="label-small">Liczba motogodzin</p>
            <p class="label is-uppercase">{{ order.device_hours }}</p>
          </div>
        </div>
        <div class="columns is-mobile is-variable is-3 is-multiline">
          <div style="display: none" class="column is-3">
            <p class="label-small">Płatne</p>
            <p class="label is-uppercase">
              {{ order.is_paid ? "TAK" : "NIE" }}
            </p>
          </div>
          <div style="display: none" class="column is-3">
            <p class="label-small">Handel</p>
            <p class="label is-uppercase">
              {{ order.is_trade ? "TAK" : "NIE" }}
            </p>
          </div>
          <div style="display: none" class="column is-3">
            <p class="label-small">Usługi</p>
            <p class="label is-uppercase">
              {{ order.is_service ? "TAK" : "NIE" }}
            </p>
          </div>
        </div>
        <div
          class="columns is-mobile is-variable is-3 is-multiline"
          v-if="order.service_type_name"
        >
          <div class="column is-6-mobile is-4-tablet">
            <p class="label-small">
              {{ dictionary["table_order_service_type_name_field_label"] }}
            </p>
            <p class="label is-uppercase">{{ order.service_type_name }}</p>
          </div>
        </div>
        <div
          class="columns is-multiline is-mobile is-variable is-3"
          v-if="order_technical.length > 0"
        >
          <div class="column is-12">
            <p class="label-small">Serwisanci asystujący</p>
            <div
              class="column is-3"
              v-for="(technical, index) in order_technical"
              :key="technical.id"
            >
              <p class="label is-uppercase">
                {{ index + 1 }}) {{ technical.firstname.toUpperCase() }}
                {{ technical.lastname.toUpperCase() }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="columns is-multiline is-mobile is-variable is-3"
          v-if="order_commute.length > 0"
        >
          <div class="column is-12">
            <p class="label-small">Dojazd</p>
            <div class="ym-table ym-table__info">
              <div
                v-for="commute in order_commute"
                :key="commute.id"
                class="ym-tableItem ym-tableItem__prl"
              >
                <div>{{ commute.commuted_at }}</div>
                <div>
                  {{ commute.city_from.toUpperCase() }}
                  <!-- <small v-if="commute.time_from !== '00:00'">({{ commute.time_from }})</small> -->
                </div>
                <div>
                  {{ commute.city_to.toUpperCase() }}
                  <!-- <small v-if="commute.time_to !== '00:00'">({{ commute.time_to }})</small> -->
                </div>
                <div>{{ commute.distance }} km</div>
                <div>&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="columns is-multiline is-mobile is-variable is-3"
          v-if="order_work.length > 0"
        >
          <div class="column is-12">
            <p class="label-small">Godziny pracy</p>
            <div class="ym-table ym-table__info">
              <div
                v-for="work in order_work"
                :key="work.id"
                class="ym-tableItem ym-tableItem__prl"
              >
                <div>{{ work.worked_at }}</div>
                <div>{{ work.time_from }} - {{ work.time_to }}</div>
                <div>{{ work.minutes }} x{{ work.workers }}</div>
                <div>{{ work.workers_time }}</div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="columns is-multiline is-mobile is-variable is-3"
          v-if="files.length > 0"
        >
          <span
            v-for="(file, index) in files"
            :key="index"
            class="column is-12"
          >
            <img :src="file.url" style="margin-bottom: 4px" />
            <p v-if="file.content" class="label is-uppercase">
              {{ file.content }}
            </p>
          </span>
        </div>
        <div
          class="columns is-mobile is-variable is-3 is-multiline"
          v-if="order.signature"
          style="display: none"
        >
          <div class="column is-6-mobile is-4-tablet">
            <p class="label-small">Podpis</p>
            <img :src="order.signature_url" />
          </div>
        </div>
      </div>
      <div
        v-if="
          order &&
          order.dedicated_fields &&
          Object.keys(order.dedicated_fields).length > 0
        "
      >
        <hr />
        <div
          v-for="(item, label, index) in order.dedicated_fields"
          class="columns is-mobile is-variable is-6"
          :key="index"
        >
          <div class="column is-12" v-if="item !== ''">
            <p class="label-small">{{ label }}</p>
            <img
              v-if="
                typeof item === 'string' &&
                item.includes('http') &&
                item.match(/[^/]+(jpg|png|gif|jpeg)$/)
              "
              :src="item"
            />
            <a
              v-else-if="typeof item === 'string' && item.includes('http')"
              :href="item"
              class="label"
              >{{ item }}</a
            >
            <p v-else-if="typeof item === 'number'" class="label">
              {{ item === 0 ? "NIE" : "TAK" }}
            </p>
            <p v-else class="label">{{ item }}</p>
          </div>
        </div>
      </div>
      <hr />
      <div class="columns is-mobile is-variable is-3 is-multiline">
        <div
          class="column is-6-mobile is-4-tablet"
          v-if="order.customer_name || order.customer_firstname"
        >
          <p class="label-small">
            {{ dictionary["table_order_customer_name_field_label"] }}
          </p>
          <p class="label is-uppercase">
            {{
              order.customer_name
                ? order.customer_name
                : `${order.customer_firstname} ${order.customer_lastname}`
            }}
          </p>
        </div>
        <div
          class="column is-6-mobile is-4-tablet"
          v-if="order.customer_address"
        >
          <p class="label-small">Adres</p>
          <p class="label is-uppercase">{{ order.customer_address }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3 is-multiline">
        <div class="column is-6-mobile is-4-tablet" v-if="order.customer_phone">
          <p class="label-small">Telefon</p>
          <p class="label is-uppercase">{{ order.customer_phone }}</p>
        </div>
        <div class="column is-6-mobile is-4-tablet" v-if="order.customer_nip">
          <p class="label-small">NIP</p>
          <p class="label is-uppercase">{{ order.customer_nip }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-12">
        <div class="column is-12" v-if="order.customer_email">
          <p class="label-small">Email</p>
          <p class="label is-uppercase" style="overflow-wrap: break-word">
            {{ order.customer_email }}
          </p>
        </div>
      </div>
      <div
        class="columns is-mobile is-variable is-12"
        v-if="order.orderFiles && order.orderFiles.length > 0"
      >
        <div class="column is-12">
          <p class="label-small">Pliki</p>
          <div style="display: flex; flex-wrap: wrap; gap: 4px">
            <a
              v-for="(file, index) in order.orderFiles"
              :key="index"
              class="tag is-primary"
              target="_blank"
              :href="`${apiStorage.content}/file/${file.name}`"
              >{{ file.label }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit, errors }">
      <form class="login" @submit.prevent="handleSubmit(onSubmit)">
        <div class="mb-72" v-if="order">
          <div class="ym-toptitle-box">
            <div id="toptitle" class="level ym-toptitle">
              <div class="level-left">
                <h2 class="title">Protokół serwisowy</h2>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['service_fault'] && errors['service_fault'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline is-multiline'
            "
            style="display: none"
          >
            <div class="column ym-column is-12-mobile is-3-tablet">
              <div class="ym-label">
                <label for="f_company_full">{{
                  dictionary["table_order_service_fault_field_label"]
                }}</label>
                <span
                  v-if="
                    errors['service_fault'] &&
                    errors['service_fault'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["service_fault"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-12-mobile is-9-tablet">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider name="service_fault">
                    <textarea
                      name="service_fault"
                      v-model="protocol.service_fault"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['service_description'] &&
              errors['service_description'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline is-multiline'
            "
            style="display: none"
          >
            <div class="column ym-column is-12-mobile is-3-tablet">
              <div class="ym-label">
                <label for="f_company_full">{{
                  dictionary["table_order_service_description_field_label"]
                }}</label>
                <span
                  v-if="
                    errors['service_description'] &&
                    errors['service_description'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["service_description"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-12-mobile is-9-tablet">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider name="service_description">
                    <textarea
                      name="service_description"
                      v-model="protocol.service_description"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['technical_service'] &&
              errors['technical_service'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline is-multiline'
            "
            style="display: none"
          >
            <div class="column ym-column is-12-mobile is-3-tablet">
              <div class="ym-label">
                <label for="f_company_full">{{
                  dictionary["table_order_technical_service_field_label"]
                }}</label>
                <span
                  v-if="
                    errors['technical_service'] &&
                    errors['technical_service'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["technical_service"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-12-mobile is-9-tablet">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider name="technical_service">
                    <textarea
                      name="technical_service"
                      v-model="protocol.technical_service"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['technical_notes'] && errors['technical_notes'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline is-multiline'
            "
          >
            <div class="column ym-column is-12-mobile is-3-tablet">
              <div class="ym-label">
                <label for="f_company_full">{{
                  dictionary["table_order_technical_notes_field_label"]
                }}</label>
                <span
                  v-if="
                    errors['technical_notes'] &&
                    errors['technical_notes'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["technical_notes"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-12-mobile is-9-tablet">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider name="technical_notes">
                    <textarea
                      name="technical_notes"
                      v-model="protocol.technical_notes"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <!-- 
          <div
            :class="
              errors['device_hours'] && errors['device_hours'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline is-multiline'
            "
          >
            <div class="column ym-column is-12-mobile is-3-tablet">
              <div class="ym-label">
                <label for="f_company_full">Aktualizacja motogodzin</label>
                <span
                  v-if="
                    errors['device_hours'] && errors['device_hours'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["device_hours"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-12-mobile is-9-tablet">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider
                    rules="required|min_value:1"
                    name="device_hours"
                  >
                    <input
                      name="device_hours"
                      type="number"
                      v-model="protocol.device_hours"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          -->
          <div
            :class="
              errors['device_serial'] && errors['device_serial'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline is-multiline'
            "
          >
            <div class="column ym-column is-12-mobile is-3-tablet">
              <div class="ym-label">
                <label for="f_company_full">{{
                  dictionary["table_order_device_serial_field_label"]
                }}</label>
                <span
                  v-if="
                    errors['device_serial'] &&
                    errors['device_serial'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["device_serial"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-12-mobile is-9-tablet">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider name="device_serial">
                    <input
                      name="device_serial"
                      type="text"
                      v-model="protocol.device_serial"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <!-- 
          <div
            :class="
              errors['device_area'] && errors['device_area'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline is-multiline'
            "
          >
            <div class="column ym-column is-12-mobile is-3-tablet">
              <div class="ym-label">
                <label for="f_company_full">Liczba ha</label>
                <span
                  v-if="
                    errors['device_area'] && errors['device_area'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["device_area"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-12-mobile is-9-tablet">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider rules="required" name="device_area">
                    <input
                      name="device_area"
                      type="number"
                      v-model="protocol.device_area"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['device_bales'] && errors['device_bales'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline is-multiline'
            "
          >
            <div class="column ym-column is-12-mobile is-3-tablet">
              <div class="ym-label">
                <label for="f_company_full">Liczba bali</label>
                <span
                  v-if="
                    errors['device_bales'] && errors['device_bales'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["device_bales"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-12-mobile is-9-tablet">
              <div class="field">
                <div class="control is-medium">
                  <ValidationProvider rules="required" name="device_bales">
                    <input
                      name="device_bales"
                      type="number"
                      v-model="protocol.device_bales"
                      class="input is-medium is-uppercase"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          -->
          <div
            :class="
              errors['is_paid'] && errors['is_paid'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline is-multiline'
            "
            style="display: none"
          >
            <div class="column ym-column totop is-3-tablet is-12-mobile">
              <div class="ym-label">
                <label for="f_dm_company">Płatne</label>
                <span
                  v-if="errors['is_paid'] && errors['is_paid'].length > 0"
                  class="help is-danger"
                  >{{ errors["is_paid"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-12-mobile is-9-tablet">
              <div class="ym-rowbor">
                <div class="level ym-rowbor-item">
                  <div class="ym-radios w150px">
                    <label>
                      <ValidationProvider name="is_paid">
                        <input
                          type="radio"
                          name="is_paid"
                          v-model="protocol.is_paid"
                          :value="true"
                        />
                        <span>Tak</span>
                      </ValidationProvider>
                    </label>
                    <label>
                      <ValidationProvider name="is_paid">
                        <input
                          type="radio"
                          name="is_paid"
                          v-model="protocol.is_paid"
                          :value="false"
                        />
                        <span>Nie</span>
                      </ValidationProvider>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['is_trade'] && errors['is_trade'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline is-multiline'
            "
            style="display: none"
          >
            <div class="column ym-column totop is-3-tablet is-12-mobile">
              <div class="ym-label">
                <label for="f_dm_company">Handel</label>
                <span
                  v-if="errors['is_trade'] && errors['is_trade'].length > 0"
                  class="help is-danger"
                  >{{ errors["is_trade"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-12-mobile is-9-tablet">
              <div class="ym-rowbor">
                <div class="level ym-rowbor-item">
                  <div class="ym-radios w150px">
                    <label>
                      <ValidationProvider name="is_trade">
                        <input
                          type="radio"
                          name="is_trade"
                          v-model="protocol.is_trade"
                          :value="true"
                        />
                        <span>Tak</span>
                      </ValidationProvider>
                    </label>
                    <label>
                      <ValidationProvider name="is_trade">
                        <input
                          type="radio"
                          name="is_trade"
                          v-model="protocol.is_trade"
                          :value="false"
                        />
                        <span>Nie</span>
                      </ValidationProvider>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['is_warranty'] && errors['is_warranty'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline is-multiline'
            "
          >
            <div class="column ym-column totop is-3-tablet is-12-mobile">
              <div class="ym-label">
                <label for="f_dm_company">{{
                  dictionary["table_order_is_warranty_field_label"]
                }}</label>
                <span
                  v-if="
                    errors['is_warranty'] && errors['is_warranty'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["is_warranty"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-12-mobile is-9-tablet">
              <div class="ym-rowbor">
                <div class="level ym-rowbor-item">
                  <div class="ym-radios w150px">
                    <label>
                      <ValidationProvider rules="required" name="is_warranty">
                        <input
                          type="radio"
                          name="is_warranty"
                          v-model="protocol.is_warranty"
                          :value="true"
                        />
                        <span>Tak</span>
                      </ValidationProvider>
                    </label>
                    <label>
                      <ValidationProvider rules="required" name="is_warranty">
                        <input
                          type="radio"
                          name="is_warranty"
                          v-model="protocol.is_warranty"
                          :value="false"
                        />
                        <span>Nie</span>
                      </ValidationProvider>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['is_service'] && errors['is_service'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline is-multiline'
            "
            style="display: none"
          >
            <div class="column ym-column totop is-3-tablet is-12-mobile">
              <div class="ym-label">
                <label for="f_dm_company">{{
                  dictionary["table_order_is_service_field_label"]
                }}</label>
                <span
                  v-if="errors['is_service'] && errors['is_service'].length > 0"
                  class="help is-danger"
                  >{{ errors["is_service"][0] }}</span
                >
              </div>
            </div>
            <div class="column ym-column is-12-mobile is-9-tablet">
              <div class="ym-rowbor">
                <div class="level ym-rowbor-item">
                  <div class="ym-radios w150px">
                    <label>
                      <ValidationProvider name="is_service">
                        <input
                          type="radio"
                          name="is_service"
                          v-model="protocol.is_service"
                          :value="true"
                        />
                        <span>Tak</span>
                      </ValidationProvider>
                    </label>
                    <label>
                      <ValidationProvider name="is_service">
                        <input
                          type="radio"
                          name="is_service"
                          v-model="protocol.is_service"
                          :value="false"
                        />
                        <span>Nie</span>
                      </ValidationProvider>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['order_technical'] && errors['order_technical'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline is-multiline'
            "
            style="display: none"
          >
            <div class="column ym-column totop is-3-tablet is-12-mobile">
              <div class="ym-label">
                <label for="f_dm_company">Serwisanci asystujący</label>
                <span
                  v-if="
                    errors['order_technical'] &&
                    errors['order_technical'].length > 0
                  "
                  class="help is-danger"
                  >{{ errors["order_technical"][0] }}</span
                >
              </div>
            </div>
            <div class="column is-9">
              <div
                v-if="order_technical.length > 0"
                style="margin-bottom: 16px"
              >
                <b-field
                  v-for="technical in order_technical"
                  :key="technical.id"
                  grouped
                >
                  <b-field expanded
                    ><input
                      class="input is-uppercase"
                      type="text"
                      v-model="technical.firstname"
                  /></b-field>
                  <b-field expanded
                    ><input
                      class="input is-uppercase"
                      type="text"
                      v-model="technical.lastname"
                    /><!-- <small v-if="commute.time_from !== '00:00'">({{ commute.time_from }})</small> --></b-field
                  >
                  <div class="ym-tableItemWrap">
                    <div
                      class="button"
                      @click="removeOrderTechnical(technical.id)"
                    >
                      <icon name="x"></icon>
                    </div>
                    <div class="button" @click="editOrderTechnical(technical)">
                      <icon name="check"></icon>
                    </div>
                  </div>
                </b-field>
              </div>

              <b-field grouped>
                <b-field expanded>
                  <input
                    name="order_technical_firstname"
                    type="text"
                    v-model="order_technical_item.firstname"
                    placeholder="Imię"
                    class="input is-medium is-uppercase"
                  />
                </b-field>
                <b-field expanded>
                  <input
                    name="order_technical_lastname"
                    type="text"
                    v-model="order_technical_item.lastname"
                    placeholder="Nazwisko"
                    class="input is-medium is-uppercase"
                  />
                </b-field>
                <b-field expanded
                  ><div
                    type="button"
                    @click="addOrderTechnical"
                    class="button is-medium is-dark"
                    v-if="online"
                  >
                    dodaj
                  </div></b-field
                >
              </b-field>
            </div>
            <ValidationProvider name="order_technical">
              <input
                type="hidden"
                name="order_technical"
                v-model="order_technical"
              />
            </ValidationProvider>
          </div>
          <div
            :class="
              errors['order_work'] && errors['order_work'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline is-multiline'
            "
            style="display: none"
          >
            <div class="column ym-column totop is-8">
              <div class="ym-label">
                <label for="f_dm_company">Godziny pracy</label>
                <span
                  v-if="errors['order_work'] && errors['order_work'].length > 0"
                  class="help is-danger"
                  >{{ errors["order_work"][0] }}</span
                >
              </div>
            </div>
            <div
              class="column is-6-mobile is-4-tablet"
              style="
                display: flex;
                align-items: center;
                justify-content: flex-end;
              "
            >
              <div class="ym-label">
                <label
                  for="f_dm_company"
                  v-if="order && order.work_workers_time !== `00:00`"
                  >{{ order.work_workers_time }}</label
                >
              </div>
            </div>
            <div class="column is-12">
              <div v-if="order_work.length > 0" class="ym-table ym-table__info">
                <div
                  v-for="work in order_work"
                  :key="work.id"
                  class="ym-tableItem ym-tableItem__prl"
                >
                  <div>{{ work.worked_at }}</div>
                  <div>{{ work.time_from }} - {{ work.time_to }}</div>
                  <div>{{ work.minutes }} x{{ work.workers }}</div>
                  <div>{{ work.workers_time }}</div>
                  <div></div>
                  <div @click="removeWorkItem(work.id)">
                    <icon name="x"></icon>
                  </div>
                </div>
              </div>
              <b-field grouped>
                <b-field expanded>
                  <b-datepicker
                    :mobile-native="false"
                    name="order_work_date"
                    v-model="order_work_item.date"
                    placeholder="Data"
                    icon="calendar-today"
                    :first-day-of-week="1"
                    position="is-top-right"
                    trap-focus
                  >
                  </b-datepicker>
                </b-field>
                <b-field expanded>
                  <b-timepicker
                    size="is-medium"
                    v-model="order_work_item.time_from"
                    placeholder="Od"
                  ></b-timepicker>
                </b-field>
                <b-field expanded>
                  <b-timepicker
                    size="is-medium"
                    v-model="order_work_item.time_to"
                    placeholder="Do"
                  ></b-timepicker>
                </b-field>
                <b-field expanded>
                  <input
                    name="order_work_distance"
                    type="text"
                    v-model="order_work_item.workers"
                    placeholder="Liczba serwisantów"
                    class="input is-medium is-uppercase"
                  />
                </b-field>
                <b-field expanded
                  ><div
                    type="button"
                    @click="addWorkItem"
                    class="button is-medium is-dark"
                    v-if="online"
                  >
                    dodaj
                  </div></b-field
                >
              </b-field>
            </div>
            <ValidationProvider name="order_work">
              <input type="hidden" name="order_work" v-model="order_work" />
            </ValidationProvider>
          </div>
          <div
            v-show="online"
            class="columns is-mobile is-variable is-3 is-multiline is-multiline"
          >
            <div class="column ym-column totop is-12">
              <div class="ym-label">
                <label for="f_dm_company">{{
                  dictionary["table_order_image_field_label"]
                }}</label>
              </div>
            </div>
            <div class="column is-12" style="margin-bottom: 24px">
              <!-- 
              <b-upload
                @input="handleFileChange(file)"
                accept="image/*"
                v-model="file"
                v-if="files.length < 3"
              >
                <a class="button is-primary is-fullwidth">
                  <b-icon icon="upload"></b-icon>
                  <span>Dodaj {{ dictionary['table_order_image_field_label'] }}</span>
                </a>
              </b-upload>
            -->
              <b-upload
                @input="handleFileChange($event)"
                accept="image/*"
                v-model="file_temp"
                multiple
              >
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"> </b-icon>
                    </p>
                    <p>
                      Dodaj {{ dictionary["table_order_image_field_label"] }}
                    </p>
                  </div>
                </section>
              </b-upload>
            </div>
            <div class="column is-12">
              <div class="columns is-multiline">
                <span
                  v-for="(file, index) in files"
                  :key="index"
                  class="column is-12-mobile is-4-tablet"
                >
                  <img :src="file.id ? file.url : file.image_stream" />
                  <!-- <p v-if="file.id" style="margin-bottom: 8px">
                    {{ file.content }}
                  </p> -->
                  <input
                    name="file_content"
                    v-model="file.content"
                    class="input is-medium"
                    style="margin-bottom: 8px"
                  />
                  <div class="buttons">
                    <b-button
                      @click="removeOrderImage(file.id, index)"
                      type="is-dark"
                      expanded
                    >
                      <span
                        >Usuń
                        {{ dictionary["table_order_image_field_label"] }}</span
                      >
                    </b-button>
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div
            :class="
              errors['signature'] && errors['signature'].length > 0
                ? 'columns is-mobile is-variable is-3 is-multiline is-multiline v-field v-error'
                : 'columns is-mobile is-variable is-3 is-multiline is-multiline'
            "
            style="display: none"
          >
            <div class="column ym-column totop is-10">
              <div class="ym-label">
                <label for="f_dm_company">Podpis klienta</label>
              </div>
            </div>
            <div class="column is-2">
              <div v-show="signature" id="clearDraw" class="button is-light">
                <icon name="x"></icon><span>wyczyść</span>
              </div>
            </div>
            <div class="column is-12">
              <div id="drawContainer" class="draw-container"></div>
              <!-- <span v-if="errors['signature'] && errors['signature'].length > 0"
                class="help is-danger">{{ errors['signature'][0] }}</span>   -->
            </div>
            <ValidationProvider name="signature">
              <input
                type="hidden"
                name="signature"
                ref="sign"
                v-model="signature"
              />
            </ValidationProvider>
          </div>
        </div>
        <div class="confirmButtons" v-if="online">
          <router-link
            tag="a"
            :to="{ name: 'Service' }"
            class="button is-medium is-light"
            >Zamknij</router-link
          >
          <div
            class="button is-medium is-dark"
            v-if="(order && order.status_id === 10) || order.status_id === 20"
            @click="changeOrderStatus(30)"
          >
            Przyjmij zlecenie
          </div>
          <div
            v-if="order && order.status_id === 30"
            style="display: flex; gap: 8px"
          >
            <button type="submit" class="button is-medium is-primary">
              <span>Zapisz</span>
            </button>
            <div
              class="button is-medium is-success"
              @click="handleSubmit(() => onSubmit(true))"
            >
              <icon name="check"></icon><span>Wykonane</span>
            </div>
          </div>
        </div>
        <div class="confirmButtons" v-else>
          <div class="button is-medium is-fullwidth is-error">
            Brak połączenia z internetem
          </div>
        </div>
      </form>
    </ValidationObserver>
    <hr />
    <div class="ym-toptitle-box">
      <div id="toptitle" class="level ym-toptitle">
        <div class="level-left">
          <h2 class="title">
            {{ dictionary["table_order_notes_field_label"] }}
          </h2>
        </div>
      </div>
    </div>
    <OrderNoteCard
      v-for="note in orderNotes"
      :key="note.id"
      :note="note"
      @update="() => getOrderNotes($route.params.id)"
    />
    <ValidationObserver v-slot="{ handleSubmit, errors }" ref="observer">
      <form @submit.prevent="handleSubmit(onOrderNoteSubmit)">
        <TextArea
          rules="required"
          name="note_note"
          v-model="order_note.note"
          :s2t="true"
          :text="dictionary['table_order_notes_add_field_label']"
          :label="dictionary['table_order_notes_note_field_label']"
        />

        <!-- <div :class="errors['note_note'] && errors['note_note'].length > 0 ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error' : 'columns is-mobile is-variable is-3 is-multiline'">
          <div class="column ym-column is-3-tablet is-12-mobile" style="align-items: baseline;">
            <div class="ym-label">
              <label for="f_company_full">{{ dictionary['table_order_notes_add_field_label'] }}</label>
              <span v-if="errors['note_note'] && errors['note_note'].length > 0"
                class="help is-danger">{{ errors['note_note'][0] }}</span>
            </div>
          </div>
          <div class="column ym-column is-9-tablet is-12-mobile">
            <div class="field">
              <label for="note_note">{{ dictionary['table_order_notes_note_field_label'] }}</label>
              <div class="control is-medium">
                <ValidationProvider rules="required" name="note_note">
                  <textarea name="note_note" v-model="order_note.note" class="input is-medium" />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div> -->
        <div
          :class="
            errors['note_service_time'] &&
            errors['note_service_time'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div class="column ym-column is-3-tablet is-12-mobile">
            <div class="ym-label">
              <span
                v-if="
                  errors['note_service_time'] &&
                  errors['note_service_time'].length > 0
                "
                class="help is-danger"
                >{{ errors["note_service_time"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9-tablet is-12-mobile">
            <div class="field">
              <label for="note_service_time"
                >{{ dictionary["table_order_notes_service_time_field_label"] }}
                <small>(00:00:00 (hh:mm:ss))</small></label
              >
              <div class="control is-medium">
                <ValidationProvider rules="" name="note_service_time">
                  <input
                    name="note_service_time"
                    placeholder="00:00:00"
                    v-maska="'##:##:##'"
                    type="text"
                    v-model="order_note.service_time"
                    class="input is-medium is-uppercase"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          style="display: flex; justify-content: flex-end; margin-bottom: 86px"
        >
          <button class="button is-medium is-primary">
            <icon name="check"></icon><span>Dodaj</span>
          </button>
        </div>
      </form>
      <div class="confirmButtons" v-if="!online">
        <div class="button is-medium is-fullwidth is-error">
          Brak połączenia z internetem
        </div>
      </div>
    </ValidationObserver>
    <custom-modal v-model="showStatusModal" @closed="emptyModalTrigger">
      <template v-slot:title>Zmiana statusu</template>
      <div style="margin-top: 16px; margin-bottom: 16px; padding: 0 16px">
        <b-field label="Status">
          <b-select v-model="modalStatusId" placeholder="Wybierz">
            <option
              v-for="item in status
                .filter((item) => item.is_active)
                .filter((item) => item.id !== 10)
                .sort((a, b) => a.id - b.id)"
              :value="item.id"
              :key="item.id"
              :style="`color: ${item.colour}`"
            >
              {{ item.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <template v-slot:actions>
        <div class="button is-black is-medium" @click="changeOrderStatus">
          Zmień
        </div>
      </template>
    </custom-modal>
  </section>
</template>

<script>
import Konva from "konva";
import FixedHeader from "vue-fixed-header";
import { mapActions, mapGetters } from "vuex";
import YMmodal from "@/components/Modal.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import CustomModal from "@/components/CustomModal";
import OrderNoteCard from "@/components/OrderNoteCard.vue";
import TextArea from "@/components/TextArea.vue";

export default {
  name: "ServiceDetails",
  components: {
    FixedHeader,
    ValidationObserver,
    ValidationProvider,
    CustomModal,
    OrderNoteCard,
    TextArea,
  },
  data: function () {
    return {
      orderNotes: [],
      order_note: {
        note: "",
        service_time: "",
      },
      warrantyStatus: [],
      order: {},
      protocol: {},
      serviceType: null,
      order_service: [],
      order_commute: [],
      order_commute_item: {
        date: new Date(),
        city_from: null,
        city_to: null,
        distance: null,
      },
      order_work: [],
      order_work_item: {
        date: new Date(),
        time_from: null,
        time_to: null,
        workers: 1,
      },
      captionSize: {
        width: 800,
        height: 300,
      },
      canvas: document.createElement("canvas"),
      isPaint: false,
      lastPointerPosition: null,
      signature: null,
      mode: "brush",
      stage: null,
      file_temp: [],
      files: [],
      order_technical: [],
      order_technical_item: {
        firstname: null,
        lastname: null,
      },
      online: navigator.onLine,
      showStatusModal: false,
      modalStatusId: null,
    };
  },
  mounted() {
    this.getOrder(true);
    this.getServiceType();
    this.getCommuteItems();
    this.getWorkItems();
    this.getOrderImage();
    this.getOrderTechnical();
    this.getWarrantyStatus();
    this.getOrderServiceItems(parseInt(this.$route.params.id));
    this.getOrderNotes(parseInt(this.$route.params.id));
    window.addEventListener("online", this.onchange);
    window.addEventListener("offline", this.onchange);
    this.onchange();
  },
  beforeDestroy() {
    window.removeEventListener("online", this.onchange);
    window.removeEventListener("offline", this.onchange);
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      status: "auth/status",
      dictionary: "dictionary",
      apiStorage: "apiStorage",
    }),
    name: function () {
      return this.order.number;
    },
    warrantyName() {
      const status = this.warrantyStatus.find(
        (item) => item.id === this.order.warranty_status_id
      );
      return status?.name;
    },
  },
  methods: {
    ...mapActions({
      getOrderCall: "orders/getOrder",
      removeOrderCall: "orders/removeOrder",
      changeOrderStatusCall: "orders/changeOrderStatus",
      getServiceTypeCall: "orders/serviceType",
      postCommuteItemsCall: "orders/postCommuteItems",
      getCommuteItemsCall: "orders/getCommuteItems",
      removeCommuteItemsCall: "orders/removeCommuteItems",
      postWorkItemsCall: "orders/postWorkItems",
      getWorkItemsCall: "orders/getWorkItems",
      removeWorkItemsCall: "orders/removeWorkItems",
      postOrder: "orders/postOrder",
      disabledSidebar: "setDisabledSidebar",
      getOrderImageCall: "orders/getOrderImage",
      postOrderImage: "orders/postOrderImage",
      putOrderImage: "orders/putOrderImage",
      removeOrderImageCall: "orders/removeOrderImage",
      getOrderTechnicalCall: "orders/getOrderTechnical",
      postOrderTechnicalCall: "orders/postOrderTechnical",
      editOrderTechnicalCall: "orders/editOrderTechnical",
      removeOrderTechnicalCall: "orders/removeOrderTechnical",
      getWarrantyStatusCall: "orders/warrantyStatus",
      getOrderServiceItemsCall: "orders/getOrderServiceItems",
      getOrderNotesCall: "orders/getOrderNotes",
      postOrderNote: "orders/postOrderNote",
    }),
    emptyModalTrigger() {
      this.modalStatusId = null;
    },
    getOrderServiceItems(id) {
      this.getOrderServiceItemsCall(id)
        .then((resp) => {
          this.order_service = resp;
        })
        .catch((error) => {
          this.serviceType = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getWarrantyStatus() {
      this.getWarrantyStatusCall()
        .then((resp) => (this.warrantyStatus = resp))
        .catch((error) => {
          this.serviceType = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    onchange() {
      this.online = navigator.onLine;
      this.$emit(this.online ? "online" : "offline");
    },
    getOrder(once = false) {
      this.getOrderCall({ id: this.$route.params.id })
        .then((resp) => {
          this.order = resp;
          this.modalStatusId = this.order.status_id;
        })
        .then(() => {
          if (once) {
            this.protocol.device_hours = this.order.device_hours;
            this.protocol.device_serial = this.order.device_serial;
            this.protocol.is_warranty = this.order.is_warranty;
          }

          if (this.order.status_id === 30) {
            this.draw();
          }
        })
        .catch((error) => {
          this.order = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error ? error.data.error_description : "",
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getServiceType() {
      this.getServiceTypeCall()
        .then((resp) => (this.serviceType = resp))
        .catch((error) => {
          this.serviceType = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    orderRemove() {
      this.$buefy.modal.open({
        parent: this,
        component: YMmodal,
        hasModalCard: true,
        props: {
          title: "Czy na pewno chcesz usunąć zlecenie?",
          content: `<span class='is-danger'>Usunięcia zlecenia nie można cofnąć.</span> Zlecenie pozostanie w utworzonych do tej pory protokołach.`,
          cancel: "Anuluj",
          ymHasIcon: true,
          ymIcon: "trash",
          submit: `<span class="mal50i">Usuń</span>`,
          ymConfirm: true,
          ymOnConfirm: () => {
            this.removeOrderCall({ id: this.$route.params.id })
              .then(({ data }) => {
                document.querySelector(".is-cancel").click();
                this.$router.push({ name: "Service" });
                this.$buefy.toast.open({
                  duration: 5000,
                  message: data.alert[1],
                  type: "is-success",
                  position: "is-bottom",
                });
              })
              .catch(() => {
                document.querySelector(".is-cancel").click();
              });
          },
        },
      });
    },
    changeOrderStatus(id) {
      this.changeOrderStatusCall({
        order: this.order,
        id: this.$route.params.id,
        status_id: Number(id) ? id : this.modalStatusId,
      })
        .then(() => {
          this.showStatusModal = false;
          this.getOrder();
          this.getServiceType();
        })
        .catch((error) => {
          this.order = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getCommuteItems() {
      this.getCommuteItemsCall({ id: this.$route.params.id })
        .then((resp) => (this.order_commute = resp))
        .catch((error) => {
          this.order_commute = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    addCommuteItem() {
      const isEmpty = !Object.values(this.order_commute_item).every(
        (x) => x !== null && x !== ""
      );

      if (isEmpty) {
        alert("Uzupełnij wszytkie pola dojazdu");
      } else {
        let item = {
          ...this.order_commute_item,
          order_id: this.$route.params.id,
        };
        this.postCommuteItemsCall({ item })
          .then((resp) => {
            this.getOrder();
            this.order_commute.push(resp.data);
            this.$refs.observer.refs.order_commute.validate();
          })
          .catch((error) => {
            this.order_commute = {};
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: "is-bottom",
              type: "is-danger",
            });
          });
        this.order_commute_item = {
          date: null,
          city_from: null,
          city_to: null,
          distance: null,
        };
      }
    },
    removeCommuteItem(id) {
      this.removeCommuteItemsCall({ id })
        .then(() => {
          this.getCommuteItems();
          this.getOrder();
        })
        .catch((error) => {
          this.order_commute = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getWorkItems() {
      this.getWorkItemsCall({ id: this.$route.params.id })
        .then((resp) => (this.order_work = resp))
        .catch((error) => {
          this.order_work = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    addWorkItem() {
      const isEmpty = !Object.values(this.order_work_item).every(
        (x) => x !== null && x !== ""
      );

      if (isEmpty) {
        alert("Uzupełnij wszytkie pola godzin pracy");
      } else {
        let item = {
          ...this.order_work_item,
          order_id: this.$route.params.id,
        };
        this.postWorkItemsCall({ item })
          .then((resp) => {
            this.order_work.push(resp.data);
            this.getOrder();
            this.$refs.observer.refs.order_work.validate();
          })
          .catch((error) => {
            this.order_work = {};
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: "is-bottom",
              type: "is-danger",
            });
          });
        this.order_work_item = {
          date: null,
          time_from: null,
          time_to: null,
          workers: null,
        };
      }
    },
    removeWorkItem(id) {
      this.removeWorkItemsCall({ id })
        .then(() => {
          this.getWorkItems();
          this.getOrder();
        })
        .catch((error) => {
          this.order_work = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    convertDate(date) {
      var yyyy = date.getFullYear().toString();
      var mm = (date.getMonth() + 1).toString();
      var dd = date.getDate().toString();

      var mmChars = mm.split("");
      var ddChars = dd.split("");

      return (
        yyyy +
        "-" +
        (mmChars[1] ? mm : "0" + mmChars[0]) +
        "-" +
        (ddChars[1] ? dd : "0" + ddChars[0])
      );
    },
    commuteDistanceSummary() {
      if (this.order_commute.length > 0) {
        return this.order_commute.reduce((a, b) => +a + +b.distance, 0) + " km";
      }
    },
    workMinutesSummary() {
      if (this.order_work.length > 0) {
        return (
          this.order_work.reduce((a, b) => +a + +b.workers_minutes, 0) +
          " minut"
        );
      }
    },
    draw() {
      var width = 800;
      var height = 200;
      var stage = new Konva.Stage({
        container: "drawContainer",
        width: width,
        height: height,
      });
      this.stage = stage;

      var layer = new Konva.Layer();
      stage.add(layer);
      var canvas = document.createElement("canvas");
      canvas.width = stage.width();
      canvas.height = stage.height();

      var image = new Konva.Image({
        image: canvas,
        x: 0,
        y: 0,
      });
      layer.add(image);
      stage.draw();

      var context = canvas.getContext("2d");
      context.strokeStyle = "#000";
      context.lineJoin = "round";
      context.lineWidth = 3;

      var isPaint = false;
      var lastPointerPosition;
      var mode = "brush";

      image.on("mousedown touchstart", () => {
        isPaint = true;
        this.signature = true;
        lastPointerPosition = stage.getPointerPosition();
      });

      // will it be better to listen move/end events on the window?

      stage.on("mouseup touchend", () => {
        isPaint = false;
      });

      // and core function - drawing
      stage.on("mousemove touchmove", function () {
        if (!isPaint) {
          return;
        }

        if (mode === "brush") {
          context.globalCompositeOperation = "source-over";
        }

        context.beginPath();

        var localPos = {
          x: lastPointerPosition.x - image.x(),
          y: lastPointerPosition.y - image.y(),
        };
        context.moveTo(localPos.x, localPos.y);
        var pos = stage.getPointerPosition();
        localPos = {
          x: pos.x - image.x(),
          y: pos.y - image.y(),
        };
        context.lineTo(localPos.x, localPos.y);
        context.closePath();
        context.stroke();

        lastPointerPosition = pos;
        layer.batchDraw();
      });

      document.getElementById("clearDraw").addEventListener("click", () => {
        //  var dataURL = stage.toDataURL();
        this.signature = null;
        context.clearRect(0, 0, canvas.width, canvas.height);
        layer.batchDraw();
      });
    },
    async uploadImages() {
      for (let index = 0; index < this.files.length; index++) {
        const file = this.files[index];
        file.id
          ? await this.putOrderImage({
              id: file.id,
              order_id: file.order_id,
              content: file.content,
              is_visible: file.is_visible,
            })
          : await this.postOrderImage(file);
      }
    },
    async onSubmit(finished) {
      await this.uploadImages();
      let protocol = {
        ...this.order,
        ...this.protocol,
        // status_id: 40,
        signature_stream: this.stage.toDataURL(),
      };
      if (finished) protocol["status_id"] = 40;

      await this.postOrder({ order: protocol, id: this.$route.params.id })
        .then((resp) => {
          this.getOrderImage();
          this.disabledSidebar(false);
          this.order = resp.data;
        })
        .catch((error) => {
          this.order = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    statusName: function () {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === this.order.status_id
      );
      if (status) {
        return status[0].name;
      }
    },
    statusColor: function () {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === this.order.status_id
      );
      if (status.length > 0) {
        return `color: ${status[0].colour}`;
      }
    },
    compressImage(base64) {
      const canvas = document.createElement("canvas");
      const img = document.createElement("img");
      return new Promise((resolve, reject) => {
        let imageCompressLevelTemp = 0.7;
        img.onload = function () {
          let width = img.width;
          let height = img.height;
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          resolve(canvas.toDataURL("image/jpeg", imageCompressLevelTemp));
        };
        img.onerror = function (err) {
          reject(err);
        };
        img.src = base64;
      });
    },
    handleFileChange(e) {
      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      if (e.length) {
        for (const file of e) {
          toBase64(file).then((data) => {
            this.compressImage(data).then((compressed) => {
              let payload = {
                order_id: this.order.id,
                image_stream: compressed,
                content: "",
              };
              this.files.push(payload);
              // this.file = null
            });
            this.file_temp = [];
          });
        }
      }
      // this.postOrderImage(payload)
      //   .then((resp) => {
      //     this.disabledSidebar(false);
      //     this.files.push(resp.data);
      //   })
      //   .catch((error) => {
      //     this.order = {};
      //     this.$buefy.toast.open({
      //       duration: 5000,
      //       message: error.data.error_description,
      //       position: "is-bottom",
      //       type: "is-danger",
      //     });
      //   });
    },
    getOrderImage() {
      this.getOrderImageCall({ id: this.$route.params.id })
        .then((resp) => (this.files = resp))
        .catch((error) => {
          this.order_work = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    removeOrderImage(id, index) {
      if (id) {
        this.removeOrderImageCall({ id })
          .then(() => {
            this.getOrderImage();
          })
          .catch((error) => {
            this.order_work = {};
            this.$buefy.toast.open({
              duration: 5000,
              message: error.data.error_description,
              position: "is-bottom",
              type: "is-danger",
            });
          });
      } else {
        this.files.splice(index, 1);
      }
    },
    getOrderTechnical() {
      this.getOrderTechnicalCall({ id: this.$route.params.id })
        .then((resp) => (this.order_technical = resp))
        .catch((error) => {
          this.order_technical = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error ? error.data.error_description : null,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    addOrderTechnical() {
      const isEmpty = !Object.values(this.order_technical_item).every(
        (x) => x !== null && x !== ""
      );

      if (isEmpty) {
        alert("Uzupełnij wszytkie pola");
      } else {
        let item = {
          ...this.order_technical_item,
          order_id: this.$route.params.id,
        };
        this.postOrderTechnicalCall({ item })
          .then((resp) => {
            this.getOrderTechnical();
            this.order_technical.push(resp.data);
            this.$refs.observer.refs.order_technical.validate();
          })
          .catch((error) => {
            this.order_technical = [];
            this.$buefy.toast.open({
              duration: 5000,
              message: error ? error.data.error_description : null,
              position: "is-bottom",
              type: "is-danger",
            });
          });
        this.order_technical_item = {
          firstname: null,
          lastname: null,
        };
      }
    },
    editOrderTechnical(item) {
      this.editOrderTechnicalCall(item)
        .then(() => {
          this.getOrderTechnical();
        })
        .catch((error) => {
          this.order_commute = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error ? error.data.error_description : null,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    removeOrderTechnical(id) {
      this.removeOrderTechnicalCall({ id })
        .then(() => {
          this.getOrderTechnical();
        })
        .catch((error) => {
          this.order_work = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error ? error.data.error_description : null,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    orderServiceMinutes(obj) {
      let sum = 0;
      obj.forEach(function (item) {
        sum += item.minutes;
      });
      return sum;
    },
    orderServiceCash(obj) {
      let sum = 0;
      obj.forEach(function (item) {
        sum += item.price;
      });
      return sum.toFixed(2);
    },
    onOrderNoteSubmit() {
      const payload = {
        ...this.order_note,
        order_id: this.$route.params.id,
      };

      this.postOrderNote(payload)
        .then(() => {
          this.order_note = {
            note: "",
            service_time: "",
          };
          requestAnimationFrame(() => {
            this.$refs.observer.reset();
          });
          this.getOrderNotes(this.$route.params.id);
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getOrderNotes(id) {
      this.getOrderNotesCall(id)
        .then((resp) => (this.orderNotes = resp))
        .catch((error) => {
          this.orderNotes = [];
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
  },
  destroyed() {
    this.$store.commit("orders/setCustomer", {});
  },
};
</script>

<style lang="scss" scoped>
.ym-whitebg {
  margin-bottom: 80px;
}
</style>
