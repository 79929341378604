<template>
  <div class="boards-container">
    <div
      class="ym-toptitle boards-container__board status-10"
      :style="{ 'border-top': `7px solid ${getStatus(10)?.colour}` }"
    >
      <h3 class="title">{{ getStatus(10)?.name }}</h3>

      <OrdersKanbanItem
        v-for="order in orders.get(10)"
        :key="order.id"
        :order="order"
      />
    </div>
    <div
      class="ym-toptitle boards-container__board status-30"
      :style="{ 'border-top': `7px solid ${getStatus(30)?.colour}` }"
    >
      <h3 class="title">{{ getStatus(30)?.name }}</h3>

      <OrdersKanbanItem
        v-for="order in orders.get(30)"
        :key="order.id"
        :order="order"
      />
    </div>
    <div
      class="ym-toptitle boards-container__board status-40"
      :style="{ 'border-top': `7px solid ${getStatus(40)?.colour}` }"
    >
      <h3 class="title">{{ getStatus(40)?.name }}</h3>

      <OrdersKanbanItem
        v-for="order in orders.get(40)"
        :key="order.id"
        :order="order"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OrdersKanbanItem from "./OrdersKanbanItem.vue";

export default {
  name: "OrdersKanbanView",
  props: {
    orders: {
      type: Map,
      required: true,
    },
  },
  components: {
    OrdersKanbanItem,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      dictionary: "dictionary",
      status: "auth/status",
    }),
  },
  methods: {
    getStatus(id) {
      return this.status.find((item) => item.id === id);
    },
  },
};
</script>

<style scoped>
.boards-container__board {
  flex: 1 0 auto;
  background: #ebecf0;
  border-radius: 3px;
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 10px;
  padding: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.2);
}

@media (max-width: 767px) {
  .boards-container__board {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .boards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
    grid-gap: 16px;
    min-height: 80vh;
  }
}
</style>
