<template>
  <router-link :to="`/service/${order.id}`" class="boards-container__board__card">
    <div v-if="order.priority_id > 0">
      <p class="label" :style="`color: ${order.priority.colour}`"> {{ order.priority.name }}</p>
    </div>            
    <div class="wrap">
      <div>
        <p>Numer zlecenia</p>
        <h5 class="title">{{ order.number }}</h5>
      </div>
      <div class="text-md-right ml-md-auto">
        <p>Klient</p>
        <h5 class="title">{{ order.customer_email }}</h5>
      </div>
    </div> 
    <div class="wrap" v-if="order.device_name || order.device_service_work">
      <div v-if="order.device_name">
        <p>{{ dictionary['table_order_device_name_field_label'] }}</p>
        <h5 class="title">{{ order.device_name }}</h5>
      </div>
      <div v-if="order.device_service_work" class="text-md-right ml-md-auto">
        <p>{{ dictionary['table_order_device_service_work_field_label'] }}</p>
        <h5 class="title">{{ order.device_service_work }}</h5>
      </div>
    </div>
  </router-link>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'OrdersKanbanItem',
  props: {
    order: {
      type: Object,
      required: true,
    },    
  },
  data() {
    return {
    }
  },
  computed:{
    ...mapGetters({
      dictionary: 'dictionary',
      status: 'auth/status',
    }), 
  },
  methods: {
    getStatus(id) {
      return this.status.find((item) => item.id === id)
    }
  }
}
</script>

<style scoped>
.boards-container__board__card {
  background: #FFF;
  box-shadow: 0 1px 0 rgba(9,30,66,.25);
  border-radius: 3px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.wrap {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}


@media (max-width: 991px) {
  .ym-toptitle .title {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .ml-md-auto {
  margin-left: auto;
  }
  .text-md-right {
    text-align: right;
  }
}
</style>